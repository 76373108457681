body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  transition: background 0.5s ease;
}

.content {
  min-height: 100vh;
}

.MuiContainer-root.css-pazajq {
  margin-top: 74px;
  padding: 15px;
  min-height: 100vh;
}

.clear {
  background: linear-gradient(135deg, #87CEEB 0%, #00BFFF 100%);
}

.clouds {
  background: linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%);
}

.rain {
  background: linear-gradient(135deg, #4e54c8 0%, #8f94fb 100%);
}

.thunderstorm {
  background: linear-gradient(135deg, #3a6186 0%, #89253e 100%);
}

.snow {
  background: linear-gradient(135deg, #e0eafc 0%, #cfdef3 100%);
}

.mist {
  background: linear-gradient(135deg, #eef2f3 0%, #8e9eab 100%);
}

.weather-container {
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.weather-card {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.weather-card:hover {
  transform: translateY(-5px);
}

.table-container {
  width: 100%;
}

div#root {
  width: 100%;
}
.MuiContainer-root.css-rzry99-MuiContainer-root {
  min-height: calc(100vh - 74px);
}
.MuiContainer-root.css-rzry99-MuiContainer-root {
  margin-top: 74px;
  padding: 15px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3{
  width: auto;
}
.MuiContainer-root {
  margin-top: 74px;
  padding: 15px;
}
.header-bar {
  background: rgba(255, 255, 255, 0.21);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.7px);
  -webkit-backdrop-filter: blur(18.7px);
  border: 1px solid rgba(255, 255, 255, 0.17);
}

h6.MuiTypography-root.MuiTypography-h6 {
  color: #1d1d1d;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3 {
  width: auto;
}
header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.mui-fixed {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 5px 15px;
  width: 100%;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3 {
  background: white;
  border-radius: 16px;
  backdrop-filter: blur(11.3px);
  -webkit-backdrop-filter: blur(11.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.65);
  border-radius: 16px;
  backdrop-filter: blur(8.6px);
  -webkit-backdrop-filter: blur(8.6px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.MuiToolbar-root.MuiToolbar-gutters {
  padding: 0 17px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-1hszqg0 {
  width: auto;
  background: rgba(36, 36, 36, 0.13);
  border-radius: 16px;
  backdrop-filter: blur(10.2px);
  -webkit-backdrop-filter: blur(10.2px);
  border: 1px solid rgba(36, 36, 36, 0.3);
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.65);
  border-radius: 16px;
  /*box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);*/
  backdrop-filter: blur(8.6px);
  -webkit-backdrop-filter: blur(8.6px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

h5.MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom {
  padding: 16px;
  color: black;
  border: none;
  margin: 15px 0;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 16px;
  backdrop-filter: blur(8.6px);
  -webkit-backdrop-filter: blur(8.6px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.table-container.MuiBox-root.css-1qe3sou {
  margin: 15px 0 0 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-984lnx-MuiPaper-root {
  border-right: 16px;
}
/* Media Queries */
@media only screen and (max-width: 600px) {
  body {
    .MuiContainer-root.css-rzry99-MuiContainer-root {
      margin-top: 65px;
      padding: 15px;
    }
  }
}
